import React, { useState } from 'react'
import Layout from '../components/common/Layout'
import Fade from 'react-reveal/Fade'
import Partnerships from '../components/partnerships/Partnerships'

const PartnershipsPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Join our ecosystem of Atlas partners. We offer a range of partnership opportunities for individuals and organizations. Get in touch to learn more.',
        title: 'Become an Atlas partner',
        type: 'website'
      }}
      title={'Become an Atlas partner'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <Partnerships />
        </Fade>
      </main>
    </Layout>
  )
}

export default PartnershipsPage
