import React, { useState } from 'react'
import styles from './Partnerships.module.scss'
import Form from './Form'

const Partnerships = () => {
  return (
    <>
      <div className={styles.header}>
        <div className={styles.headerContent}>
          <div className={styles.heading}>
            <h1>Apply for Partnership</h1>
            <p>
              Fill out the form below and we'll get in touch with you as soon as
              possible.
            </p>
          </div>
          <Form
            submitText={'Become a partner'}
            formName={'Partnership'}
            successMessage={
              'We look forward to learning more about your organization and your goals. You will be contacted shortly to schedule a convenient time.'
            }
          />
        </div>
      </div>
    </>
  )
}

export default Partnerships
